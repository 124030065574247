<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            Send Report</v-card-title
        >
        <v-card-text>
            <v-form v-model="valid" lazy-validation>
                <v-row class="align-end my-2" no-gutters>
                    <v-col>
                        <v-combobox
                            v-model="email.to"
                            :items="emailOptions"
                            :filter="filter"
                            :search-input.sync="search"
                            multiple
                            small-chips
                            label="To:"
                            flat
                            clearable
                            @blur="addCustomEmail"
                        ></v-combobox>
                    </v-col>
                </v-row>
                <v-row class="align-end my-4" no-gutters>
                    <v-col class="">
                        <v-combobox
                            v-model="email.cc"
                            :items="emailOptions"
                            :filter="filter"
                            :search-input.sync="search"
                            multiple
                            small-chips
                            label="Cc:"
                            flat
                            clearable
                            @blur="addCustomEmail"
                        ></v-combobox>
                    </v-col>
                </v-row>
                <v-row class="align-end mt-8" no-gutters>
                    <v-col class="">
                        <v-text-field
                            v-model="email.subject"
                            label="Subject:"
                            dense
                            :rules="[v => !!v || 'Required']"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="email.to.lenght < 1 || email.cc.lenght < 1 || !valid"
                rounded
                elevation="0"
                color="primary"
                class="ma-3"
                @click="sendEmail"
                :loading="loading"
            >
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { storage } from '@/services/firebase'
import { dataURLtoFile } from '@/helpers/base64ToFile.js'
import {
    generateHTMLReport,
    loadPDFPreview,
} from '@/components/Timesheets/dailyReport.js'
import _ from 'lodash'

export default {
    name: 'SendReport',
    props: {
        project: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
        selectedReport: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
        users: {
            type: Array,
            required: true,
            default: () => [],
        },
        externalUsers: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            valid: false,
            contacts: [],
            email: { to: [], cc: [] },
            companyId: JSON.parse(localStorage.getItem('company')),
            emailsTo: [],
            emailsCc: [],
            x: 0,
            search: '',
            y: 0,
            menu: false,
            activator: null,
            attach: null,
            editing: null,
            model: [],
            model1: [],
            editingIndex: -1,
            loading: false,
            error: false,
            errorMsg: null,
            rules: {
                required: v => !!v || 'Required',
            },
            idSettingQuote: null,
            folderPdfImage: 'pdf_images',
            folderClientsPictures: 'clients_pictures',
            loadingButton: false,
            validateSent: false,
            settingsCompany: null,
            emailOptions: [],
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { contacts },
            } = await API.getContactsByClient({
                clientId: this.project.client,
            })
            this.contacts = contacts
            const {
                data: { users },
            } = await API.getLiteUsers()
            let usersEmails = []
            this.project.collaborators.forEach(collaborator => {
                const user = users.find(user => user.id == collaborator)
                if (user && user.email) {
                    usersEmails.push(user.email)
                }
            })
            const contactsEmails = contacts.map(c => c.email)
            this.email.to = contactsEmails
            this.email.cc = usersEmails
            this.emailOptions = [...contactsEmails, ...usersEmails]

            this.email.subject = `${this.selectedReport.date} Field Report - ${this.project.name}`
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getUserName(id) {
            let user = this.users.find(u => u.id == id)
            if (!user) {
                user = this.externalUsers.find(u => u.id == id)
            }
            if (user) {
                return user.name
            }
            return ''
        },
        async reportDetails() {
            try {
                const reportToShow = _.cloneDeep(this.selectedReport)
                reportToShow.createdBy = {
                    id: reportToShow.createdBy,
                    name: this.getUserName(reportToShow.createdBy),
                }
                const path = `${this.companyId}/projects/${this.project.id}/timesheets`
                const storageRef = storage().ref(path)
                let attachments = []
                if (reportToShow.attachments) {
                    for (const file of reportToShow.attachments) {
                        const profilePicRef = storageRef.child(file)
                        await profilePicRef
                            .getDownloadURL()
                            .then(URL => {
                                attachments.push({ name: file, url: URL })
                            })
                            .catch(error => {
                                error && true
                            })
                    }
                }
                for (const attachment of attachments) {
                    const isPDF = attachment.name.includes('.pdf')
                    if (isPDF) {
                        const dataURL = await loadPDFPreview(attachment.url)
                        const file = dataURLtoFile(dataURL, attachment.name)
                        attachment.firstPageURL = URL.createObjectURL(file)
                        attachment.isPDF = isPDF
                    }
                }
                reportToShow.attachments = attachments
                const htmlContent = await generateHTMLReport(
                    reportToShow,
                    this.project,
                    false
                )
                return htmlContent
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        async sendEmail() {
            try {
                this.loading = true
                // save file
                const file = await this.reportDetails()
                const currentDate = new Date()
                const path = `${this.companyId}/projects/${
                    this.project.id
                }/timesheets/${currentDate.toISOString()} Field Report - ${
                    this.project.name
                }`
                const storageRef = storage().ref(path)
                await storageRef.put(file)
                // send email
                await API.sendReportEmail({
                    subject: this.email.subject,
                    to: this.email.to,
                    cc: this.email.cc,
                    projectId: this.project.id,
                    reportId: this.selectedReport.id,
                    reportPath: `${currentDate.toISOString()} Field Report - ${
                        this.project.name
                    }`,
                })

                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        addCustomEmail() {
            let trimmedSearch = ''

            if (this.search) {
                trimmedSearch = this.search.trim()
            }

            if (trimmedSearch && this.isValidEmail(trimmedSearch)) {
                if (!this.selectedEmails.includes(trimmedSearch)) {
                    this.selectedEmails.push(trimmedSearch.toLowerCase())
                }
                this.search = ''
            }
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(email)
        },
    },
}
</script>
